import React from 'react'
import '../Sass/QuizAttempt.scss'
import QuizNavigatorQuestion from './QuizNavigatorQuestion'
const QuizNavigator = (props) => {
    let list = []
    if(props.value){
        for(let i = 1; i <= props.value; i++){
      list.push( <QuizNavigatorQuestion key={i} value={i}/>)
        }

    }
    return (
        <div className='quiz_navigator'>
          <div className='quiz_navigator_heading'><img alt='img' src='/QuizAttempt/Asset 73.png' /><p>Quiz Navigation</p></div>
          <div className='quiz_navigator_main'>
              {list}
              {/* <QuizNavigatorQuestion value='1'/>
              <QuizNavigatorQuestion value='2'/>
              <QuizNavigatorQuestion value='3'/>
              <QuizNavigatorQuestion value='4'/>
              <QuizNavigatorQuestion value='5'/>
              <QuizNavigatorQuestion value='6'/>
              <QuizNavigatorQuestion value='7'/>
              <QuizNavigatorQuestion value='8'/>
              <QuizNavigatorQuestion value='9'/> */}
          </div>
        </div>
    )
}

export default QuizNavigator
