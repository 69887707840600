import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import '../Sass/Register.scss'
class CreateAccount extends Component {
    state = {  }
    render() {
        return (
            <p className='create-account'>
            Not a member ? <Link className='link' to={this.props.to}> <span>Create Account</span> </Link>
            </p>
        );
    }
}

export default CreateAccount;