import React, { Component } from 'react'
import { FaBeer } from 'react-icons/fa';
import '../Sass/Header.scss'
class IconButton extends Component {
    render() {
        return (
            <button className={`${this.props.className} icon_button`} style={{backgroundColor: this.props.color, borderColor: this.props.color}} onClick={this.props.onClick} disabled={this.props.disabled}>
               <div> {this.props.children} </div> {this.props.value}
            </button>
        );
    }
}

export default IconButton;