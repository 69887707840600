import axios from 'axios';
import React, { Component } from 'react';
// import React from 'react'
import { Redirect } from 'react-router-dom';

const withProtectionUser = (WrapperComponent) => {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                redirect: false
            };
        }
        componentWillMount() {
            if(localStorage.getItem('token') === false || localStorage.getItem('token') === null) {
                this.setState({
                    redirect: true
                })

            }
            else{
                axios.post(`https://lmsapi.liamcrest.com/api/verify`, null, { headers: {  Authorization: localStorage.getItem('token') } }) 
                .then(res => {
                      if(res.data.status === false || res.data.data.user_data.role_id != '3'){
                        this.setState({
                            redirect: true
                        })      
                      }
                      else if(localStorage.getItem('user_id') === null ||  localStorage.getItem('user_id') === undefined){
                       localStorage.setItem('user_id', res.data.data.user_data.id)
                       localStorage.setItem('company_id', null)
                      }
                  })
            }
            
            


        }
        render() {
            if (this.state.redirect === true) {
                return <Redirect to='/Login' />
            }
            return (
                <WrapperComponent  {...this.props} />
            )


        }
    }

}

export default withProtectionUser
