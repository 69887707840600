import React from 'react'

const Info = (props) => {
    return (
        <div className='info'>
         {props.children}
         <p className='info_p'>{props.value}</p>        
        </div>
    )
}

export default Info
