import React from 'react'
import '../Sass/QuizAttempt.scss'


const QuizQuestionTextarea = (props) => {
    return (
        <>
            <textarea name={props.name} value={props.value} onChange={props.onChange} className='quiz_question_textarea' placeholder={props.placeholder} /> 

        </>
    )
}

export default QuizQuestionTextarea
