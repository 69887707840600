import React from 'react'
import { FaBriefcase, FaEnvelope, FaHandPointer, FaPhone, FaUser } from 'react-icons/fa'
import "../Sass/Table.scss"

const DataTable = (props) => {
    return (
        <table className='table' style={{backgroundColor:'#DCDCDC'}}>
  <tr>
    <th><div className='th'><FaUser className='icon' color='white' size='2rem'/><p>Firstname</p></div></th>
    <th><div className='th'><FaUser className='icon' color='white' size='2rem'/><p>Lastname</p></div></th>
    <th><div className='th'><FaEnvelope className='icon' color='white' size='2rem'/><p>Email</p></div></th>
    <th><div className='th'><FaPhone className='icon' color='white' size='2rem'/><p>Phone</p></div></th>
    <th><div className='th'><FaBriefcase className='icon' color='white' size='2rem'/><p>Feild</p></div></th>
    <th><div className='th'><FaHandPointer className='icon' color='white' size='2rem'/><p>Action</p></div></th>
  </tr>
  {props.children}
  {/* <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
    <td>50</td>
    <td>50</td>
    <td><FaTrash className='icon' color='#03375e' size='1rem'/></td>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
    <td>50</td>
    <td>50</td>
  </tr> */}
</table>
    )
}

export default DataTable
