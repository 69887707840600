import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { FaTachometerAlt } from 'react-icons/fa'
import '../Sass/Dashboard.scss'
import Card from './Card'
import Info from './Info'
import withProtection from './withProtection'
import { useParams } from 'react-router'
import withProtectionCompany from './withProtectionCompany'
const CompanyDash = () => {
    const [state, setState] = useState({})
    const {id} = useParams()
    useEffect(() => {
        axios.post(`https://lmsapi.liamcrest.com/api/company_dashboard_sats`,{company_id: id})
            .then(res => {
                setState(res.data.data)
            })

    }, [])
    return (
        <div className='dash' style={{ backgroundImage: 'url("../Dashboard/Asset 19.png")' }}>
        <Info value='Dashboard'>
            <FaTachometerAlt color='#03375e' size='1.5rem' />
        </Info>
        <div className='cards'>
            <Card key={1} name='Employees' value={state.total_employees} src='/Dashboard/Asset 15.png' />
            <Card key={2} name='Courses' value={state.total_courses} src='/Dashboard/Asset 77.png' />
            <Card key={3} name='Certificates' value={state.total_certificates} src='/Dashboard/Asset 17.png' />
        </div>
    </div>
    )
}

export default withProtectionCompany(CompanyDash)
