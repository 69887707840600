import React from 'react'
import '../Sass/QuizAttempt.scss'
import Question from './Question'
import QuizQuestionInfo from './QuizQuestionInfo'
import QuizQuestionTextarea from './QuizQuestionTextarea'
const QuizQuestion = (props) => {
    return (
        <div className='quiz_question'>

            <div className='quiz_question_info_div'>
                <QuizQuestionInfo key='Question' kay='Question' value={props.question_no} />
                <QuizQuestionInfo key='Marks' kay='Marks' value={props.quiz_total_marks} />
            </div>
            <Question value={props.question} /> 
            <QuizQuestionTextarea name='user_answer' placeholder='Type Your Answer ' value={props.value} onChange={props.onAnswer} /> 
                       
        </div>
    )
}

export default QuizQuestion
