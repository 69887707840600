import React, { Component } from 'react'
import Header from './Header.js'
import Title from './Title.js'
import '../Sass/Course.scss'
import CourseTopic from './CourseTopic.js'
import { FaCamera } from 'react-icons/fa'
import IconButton from './IconButton.js'
import AddActivity from './AddActivity.js'
import Topic from './Topic.js'
import AddTopic from './AddTopic.js'
import axios from 'axios'
import Loading from './Loading.js'
import withProtectionUser from './withProtectionUser.js'

class UserNewCourse extends Component {
    state = {
        img: '',
        loading: true,
        topics: []
    }
    handleUpload = (e) => {

    }
    componentDidMount() {
        console.log(this.props.match.params)
        axios.post(`https://lmsapi.liamcrest.com/api/topics_list`, { course_id: this.props.match.params.course_id })
            .then((res) => {
                this.setState({
                    loading: false,
                    course_name: res.data.course_name,
                    topics: res.data.data
                })
            })
    }

    render() {
        const { id, course_id } = this.props.match.params

        if (this.state.loading) {
            return (
                <Loading />
            )
        }
        else{
            let topic = []
            if(this.state.topics !== undefined){
                topic = this.state.topics.map((topic) => {
                    return <Topic user={true} course_id={course_id} topic_id={topic.id} heading={topic.name} content={topic.description} img={`url('${topic.image}')`} />
                })
            }
            return (
                <div className='course'>
                    <Header />
                    <div className='course_main' style={{ backgroundImage: 'url("../Course/Asset 63.png")' }}>
                        <Title title={this.state.course_name} />
                        {topic}
                       {localStorage.getItem('isAdmin') === true && <AddTopic id={id} /> }
                    </div>
    
                </div>
            );    
        }
        
    }
}

export default UserNewCourse