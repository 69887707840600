import React, { Component } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import '../Sass/Course.scss'
import withProtection from './withProtection';
class AddActivity extends Component {
    state = {  }
    render() {
        let img = ''
            if(this.props.type === 'file'){
              img =  <img alt='icon' src='/Course/Asset 82.png'/>
            }
            else if(this.props.type === 'quiz'){
                img =  <img alt='icon' src='/Course/Asset 80.png'/>
            }
            else if(this.props.type === 'video'){
                img =  <img alt='icon' src='/Course/Asset 81.png'/>
            }
            else if(this.props.type === 'url'){
                img = <img alt='icon' src='/Course/Asset 8.png'/>
            }
            if(this.props.delete){
                return (
                    <div className='activity' id={this.props.id}>
                
                   {img}
                    <div className='edit'>
                      <p> {this.props.name} </p>
                      <div>
                      <Link to={`/Edit_${this.props.type}/${this.props.course_id}/${this.props.topic_id}/${this.props.id}/${this.props.type}`}> <FaEdit color='white' /></Link>  
                      <FaTrashAlt color='white' onClick={this.props.delete}/>
                      </div>
                    </div>
                    </div>
                     
                );
            }
            else{
                if(this.props.url){
                    return (
                        <a href={this.props.to} target='_blank' ><div className='activity' id={this.props.id}>
                        {img}
                         <div className='edit'>
                           <p> {this.props.name} </p>  
                         </div>
                         </div>
                          </a>    
                    )
                }
                return (
                    <Link to={this.props.to}><div className='activity' id={this.props.id}>
                
                   {img}
                    <div className='edit'>
                      <p> {this.props.name} </p>
                      
                    </div>
                    </div>
                     </Link>
                );
            }
        
    }
}

export default AddActivity;