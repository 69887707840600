import './App.css';
import Routes from './Component/Routes';
function App() {

  return (
    <div className="App">
     <Routes/>
    </div>
  );
}

export default App;
