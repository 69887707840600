import React, { Component } from 'react';
import { FaEdit } from 'react-icons/fa';
import '../Sass/Course.scss'
class TopicHeading extends Component {
    state = {  }
    render() {
        return (
            <div className='topic_heading' style={{backgroundImage: 'url("/Course/Asset 67.png")'}} >
            <p>{this.props.heading}</p> <FaEdit  color='#03375e' onClick={this.props.onClick}/>
            </div>
        );
    }
}

export default TopicHeading;