import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Input from './Input.js'
import IconButton from './IconButton.js';
import axios from 'axios';
import { Redirect } from 'react-router';
import { Alert, AlertTitle } from '@material-ui/lab'
import {FaEdit} from 'react-icons/fa'
import withProtection from './withProtection.js';

const useStyles = makeStyles((theme) => ({
    modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid white',
    borderRadius: '20px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 'clamp(20rem, 35rem, 40rem)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'center',
    alignItems:'center'
  },
  btn: {
      
      color: "white"
  }
}));

 function EditQuestion(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    id: '', 
    question: '',
      answer: '',
      marks: 0
  })
  useEffect(() => {
   axios.post('https://lmsapi.liamcrest.com/api/showQuestion_data', {id: props.id})
   .then((res) => {
     if(res.data.status === 'success'){
    setState({
    id: res.data.data[0].id,
    question: res.data.data[0].question,
    answer: res.data.data[0].answer,
    marks: res.data.data[0].marks
    })
     }
   })
  },[])
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
   setState({...state, [e.target.name]: e.target.value})
  }
  const handleSubmit = () => {

   axios.post(`https://lmsapi.liamcrest.com/api/edit_question`,state)
   .then((res) => {
     if(res.data.status == 'success'){
       setState({...state, redirect: true})
     }
     else if(res.data.status == 'error'){
       setState({...state, error: true, ErrorData: res.data.message })
     }
        })
  
  }
  if(state.redirect){
    return(
      <Redirect to={`/add_question/${props.quiz_id}`} />
    )
  }

  return (
    <div className={classes.container}>
     <FaEdit className='icon' color='#03375e' onClick={handleOpen}/>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
              <img alt='logo' src='../Course/Asset 13.png'/>
              <h2>Edit Question</h2>
              {state.error && <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                  {state.ErrorData} — <strong>check it out!</strong>
                </Alert>}
            <Input key={1} type='text' name='question' placeholder='Question' value={state.question} src='/Course/Asset 15.png' onChange={handleChange}/>
            <Input key={2} type='text' name='answer' placeholder='Answer' value={state.answer} src='/Course/Asset 16.png' onChange={handleChange}/>
            <Input key={3} type='number' name='marks' placeholder='Marks' value={state.marks} src='/Course/Asset 2.png' min='0'  onChange={handleChange}    />
          <IconButton onClick={handleSubmit} className={classes.btn} value='Update Question' color='#03375e' />
          </div>
          
        </Fade>
      </Modal>
    </div>
  );
}

export default withProtection(EditQuestion)