import React from 'react'
import DashboardHeader from './DashboardHeader.js'
import '../Sass/Dashboard.scss'
import MenuItem from './MenuItem.js';
import { FaBook, FaHome, FaLink, FaTachometerAlt, FaUser, FaUsers } from 'react-icons/fa';
import withProtection from './withProtection.js';
import withProtectionCompany from './withProtectionCompany.js';
import { useParams } from 'react-router';


const CompanyDashboard = (props) => {
  const {id} = useParams()
  return (
    <>
      <DashboardHeader src='/Course/Asset 1.png' />
      <div className='dashboard'>
        <div className='dashboard_sidebar'>
          <MenuItem color='#ffb210' height='10vh' width='100%' text='Main Menu' textColor='#03375e' textFont='1.5rem'>
            <FaHome color='#03375e' size='2rem' />
          </MenuItem>
          <div>



            <MenuItem link={`/Company/${id}/dashboard`} key={1} color='#03375e' height='7vh' width='100%' text='Dashboard' textColor='white' textFont='1rem'>
              <FaTachometerAlt color='#ffb210' size='1.5rem' />
            </MenuItem>

            <MenuItem link={`/Company/${id}/links`} key={0} color='#03375e' height='7vh' width='100%' text='Links' textColor='white' textFont='1rem'>
              <FaLink color='#ffb210' size='1.5rem' />
            </MenuItem>

            <MenuItem link={`/Company/${id}/registed_courses`} key={4} color='#03375e' height='7vh' width='100%' text='Registed Courses' textColor='white' textFont='1rem'>
              <FaBook color='#ffb210' size='1.5rem' />
            </MenuItem>

            <MenuItem link={`/Company/${id}/courses`} key={2} color='#03375e' height='7vh' width='100%' text='Courses' textColor='white' textFont='1rem'>
              <FaBook color='#ffb210' size='1.5rem' />
            </MenuItem>

            <MenuItem link={`/Company/${id}/employees`} key={3} color='#03375e' height='7vh' width='100%' text='Employees' textColor='white' textFont='1rem'>
              <FaUser color='#ffb210' size='1.5rem' />
            </MenuItem>

          </div>
        </div>
        <div className='dashboard_main'>
          {props.children}
        </div>
      </div>
    </>
  )
}

export default withProtectionCompany(CompanyDashboard) 
