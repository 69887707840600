import React, { useEffect, useState } from 'react'
import { FaBook } from 'react-icons/fa'
import Info from './Info.js'
import '../Sass/Dashboard.scss'
import CourseCard from './CourseCard.js'
import axios from 'axios'
import { Redirect } from 'react-router-dom'

const UserCourse = () => {
    const [list, setList] = useState([])
    const [redirect, setRedirect] = useState(false)
    const [courseID, setCourseID] = useState()
    useEffect(() => {
    axios.get(`https://lmsapi.liamcrest.com/api/courses_list`)
    .then((res) =>{
        setList(res.data.data)
    })
    },[])
    const handleRegister = (course_id) => {
        setCourseID(course_id)
        setRedirect(true)
    }
    let List = list.map((course) => {
        return  <CourseCard  register={() => handleRegister(course.id)} key={course.id} heading={course.course_name} detail={course.course_description} src={course.course_logo}/>
    })
    if(redirect){
        return (
            <Redirect to={`/Payment/${courseID}`} />
        )
    }
    return (
        <div className='dash_course' style={{backgroundImage: 'url("../Dashboard/Asset 19.png")'}}>
        
        <Info value='Courses'>
            <FaBook color='#03375e' size='1.5rem'/>
        </Info>
        {List}
        </div>
    )
}

export default UserCourse