import React from 'react'
import '../Sass/QuizAttempt.scss'


const Question = (props) => {
    return (
        <p className='question'>
            {props.value}
        </p>
    )
}

export default Question
