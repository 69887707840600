import React, { Component } from 'react'
import { FaSignOutAlt } from 'react-icons/fa'
import { Redirect } from 'react-router'
import '../Sass/Header.scss'
import IconButton from './IconButton'
class DashboardHeader extends Component {
    state = {
        redirect: false
    }
    handleLogout = () => {
        this.setState({
            redirect: true
        })
        localStorage.clear()
    }
    render() {
        if (this.state.redirect === true) {
            if (localStorage.getItem('isEmployee')) {
                return <Redirect to='/Employee_Login' />
            } else if (localStorage.getItem('isAdmin')) {
                return <Redirect to='/Admin_Login' />
            } else {
                return (
                    <Redirect to='/Login' />
                )
            }
        }
        return (

            <div className='header'>
                <img className='img' alt='icon' src={this.props.icon || '/Asset 2.png'} />
                <div>
                    {/* <img className='profile' alt='profile' src={this.props.src || '/Asset 2.png'} /> */}
                    <IconButton className='icon' color='#1ebb90' value='Logout' onClick={this.handleLogout}>
                        <FaSignOutAlt size='1.2rem' color='#03375e' />
                    </IconButton>
                </div>
            </div>

        );
    }
}

export default DashboardHeader;