import React, { Component } from 'react';
import '../Sass/Register.scss'
class RememberMe extends Component {
    render() {
        return (
            <div className='remember'>
            <input className='regular-checkbox' type='checkbox' name={this.props.name} value={this.props.value} onChange={this.onChange} />
            <label> Remember Me </label>
            </div>
        );
    }
}

export default RememberMe;