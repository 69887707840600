import React, { Component } from 'react';
import '../Sass/Topic.scss'
import CourseTopic from './CourseTopic.js'
import AddActivity from './AddActivity.js'
import AddResource from './AddResource';
import axios from 'axios';
class Topic extends Component {
    state = {}

    handleDelete = (id, type) => {
        axios.post(`https://lmsapi.liamcrest.com/api/delete_activity`, { activity_id: id, activity_type: type })
            .then((res) => {
                if (res.data.status === 'success') {
                    axios.post(`https://lmsapi.liamcrest.com/api/getTopicActivities`, { course_id: this.props.course_id, topic_id: this.props.topic_id })
                        .then((res) => {
                            if (res.data.status === 'success') {
                                this.setState({
                                    activities: res.data.data
                                })
                            }
                        })
                }
            })
    }
    componentDidMount() {
        axios.post(`https://lmsapi.liamcrest.com/api/getTopicActivities`, { course_id: this.props.course_id, topic_id: this.props.topic_id })
            .then((res) => {
                if (res.data.status === 'success') {
                    this.setState({
                        activities: res.data.data
                    })
                }
            })
    }
    render() {
        let activities = ''
        if (this.state.activities !== undefined) {
            if (this.props.user === true) {
                activities = this.state.activities.map((item, i) => {
                    return <AddActivity key={i} url={item.type === 'url' ? true : false} to={item.type === 'url' ? item.url_activity_path : `/${item.type}/${item.id}/${item.type}`} id={item.id} course_id={this.props.course_id} topic_id={this.props.topic_id} type={item.type} name={item[`${item.type}_activity_name`]} />
                })
            }
            else {
                activities = this.state.activities.map((item, i) => {
                    return <AddActivity key={i} url={item.type === 'url' ? true : false} to={item.type === 'url' ? item.url_activity_path : `/${item.type}/${item.id}/${item.type}`} id={item.id} course_id={this.props.course_id} topic_id={this.props.topic_id} type={item.type} name={item[`${item.type}_activity_name`]} delete={() => this.handleDelete(item.id, item.type)} />
                })
            }

        }
        return (
            <>
                <div className='course_container'>
                    <CourseTopic heading={this.props.heading} content={this.props.content} />
                    <div className='upload'>
                        <div className='course_img' style={{ backgroundImage: this.props.img || 'url("../Course/Asset 75.png")' }}>
                            {/* <label for='upload_img'><FaCamera className='course_icon' color='#03375e' /></label> */}
                        </div>
                        {/* <input className='input' id='upload_img' name='image' type='file' accept="image/x-png,image/gif,image/jpeg" onChange={this.handleUpload} /> */}
                    </div>
                </div>
                <div className='resource'>
                  {localStorage.getItem('isAdmin') && <AddResource course_id={this.props.course_id} topic_id={this.props.topic_id} />} 
                </div>
                <br />
                <hr />
                {activities !== '' ? <h2>List of Activities</h2> : null}
                <div className="add_activity">
                    {activities}
                </div>
                <hr />
            </>

        );
    }
}

export default Topic;