import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Input from './Input.js'
import IconButton from './IconButton.js';
import axios from 'axios';
import { Redirect } from 'react-router';
import { Alert, AlertTitle } from '@material-ui/lab'
import { FaPlusCircle } from 'react-icons/fa'
import withProtection from './withProtection.js';
import AddResourceHeading from './AddResourceHeading.js';
import AddResourceCard from './AddResourceCard.js';



const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    border: '2px solid white',
    borderRadius: '20px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 'clamp(30rem, 40rem, 45rem)',
    minHeight: 'clamp(20rem, 30rem, 40rem)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  btn: {

    color: "white"
  },
  cards: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

const AddResource = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({ quiz_activity_id: props.id })
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmit = () => {

    axios.post(`https://lmsapi.liamcrest.com/api/addQuestion`, state)
      .then((res) => {
        if (res.data.status == 'success') {
          setState({ ...state, redirect: true })
        }
        else if (res.data.status == 'error') {
          setState({ ...state, error: true, ErrorData: res.data.message })
        }
      })

  }
  if (state.redirect) {
    return (
      <Redirect to={`/add_question/${props.id}`} />
    )
  }
  return (
    <div className={classes.container}>
      <IconButton value='Add an activity or resource' color='#1ebb90' onClick={handleOpen}>
        +
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={{ backgroundImage: `url('/AddResource/Asset 1.png')` }}>

            <AddResourceHeading />
            <div className={classes.cards}>
              <AddResourceCard key='1' link={`/Add_file/${props.course_id}/${props.topic_id}`} src='/AddResource/Asset 6.png' value='File' />
              <AddResourceCard key='2' link={`/Add_quiz/${props.course_id}/${props.topic_id}`} src='/AddResource/Asset 9.png' value='Quiz' />
              <AddResourceCard key='3' link={`/Add_video/${props.course_id}/${props.topic_id}`} src='/AddResource/Asset 10.png' value='Video' />
              <AddResourceCard key='4' link={`/Add_url/${props.course_id}/${props.topic_id}`} src='/AddResource/Asset 8.png' value='URL'/>
            </div>
          </div>

        </Fade>
      </Modal>
    </div>
  );
}

export default AddResource
