import React from 'react'
import {
    Route,
} from "react-router-dom";
import UserDashboard from './UserDashboard';
import UserCourse from './UserNewCourse';
import UserCourses from './UserCourses'
import UserRegistedCourses from './UserRegistedCourses';
const CompanyRoutes = () => {
    return (
        <UserDashboard>
            <Route exact path='/User_dashboard/:id/courses' component={UserCourses} />
            <Route exact path='/User_dashboard/:id/registed_courses' component={UserRegistedCourses} />
        </UserDashboard>

    )
}

export default CompanyRoutes