import React, { useEffect, useState } from 'react'
import { FaBook } from 'react-icons/fa'
import Info from './Info.js'
import '../Sass/Dashboard.scss'
import CourseCard from './CourseCard.js'
import axios from 'axios'
import { useParams } from 'react-router'

const UserRegistedCourses = () => {
    const [list, setList] = useState([])
   const {id} = useParams()
    useEffect(() => {
    axios.post(`https://lmsapi.liamcrest.com/api/individual_registered_courses`, {user_id: id})
    .then((res) =>{
        if(res.data.status !== 'error'){
            setList(res.data.data)
        }
        
    })
    },[])
    let List = ''
    if(list){
       List =  list.map((course) => {
            return  <CourseCard id={`/user_course/${id}/${course.id}`} key={course.id} heading={course.course_name} detail={course.course_description} src={course.course_logo}/>
        })
    }
    
    return (
        <div className='dash_course' style={{backgroundImage: 'url("../Dashboard/Asset 19.png")'}}>
        <Info value='Registed Courses'>
            <FaBook color='#03375e' size='1.5rem'/>
        </Info>
        {List}
        
        </div>
    )
}

export default UserRegistedCourses
