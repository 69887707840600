import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import '../Sass/Register.scss'
class ForgetPassword extends Component {
    state = {
        redirect: false
    }
    handleForget = () => {
        this.setState({
          redirect: true
        })
    }
    render() {
        if(this.state.redirect){
            return (
                <Redirect to='/forget_password' />
            )
        }
        return (
            <p className='forget' onClick={this.handleForget}>
                Forgot Your Password?
            </p>
        );
    }
}

export default ForgetPassword;