import React, { Component } from 'react'
import '../Sass/Pillar.scss'
class Pillar extends Component {
    render() {
        return (
            <div className='Pillar' style={{backgroundImage : `url('${this.props.src}')`, backgroundSize:'contain', backgroundRepeat:'no-repeat' }}>
                    <p className='heading'>{this.props.heading}</p>
                    <p className='descripction'>{this.props.des}</p> 
                 </div>
        );
    }
}

export default Pillar;