import React, { Component } from 'react'
import { FaSignOutAlt } from 'react-icons/fa'
import '../Sass/Header.scss'
import IconButton from './IconButton'
import { Redirect } from 'react-router'
class Header extends Component {
    state = {
        redirect: false
    }
    handleLogout = () =>{
        localStorage.removeItem('token')
        localStorage.clear()
        this.setState({
            redirect: true
        })
    }
    render() {
        if(this.state.redirect === true){
            return(
                <Redirect to='/Login' />
            )
        }
        return (
            <div>
            <div className='header'>
             <img className='img' alt='icon' src='/Asset 2.png'/>
             <div>
             <img className='profile' alt='profile' src={this.props.src || '/Asset 2.png'} />
             <IconButton className='icon' color = '#1ebb90' value='Logout' onClick={this.handleLogout}>
                 <FaSignOutAlt size='1.2rem' color='#03375e'/>
             </IconButton>
            </div>
            </div>
            <img className='carsole' src='/Header/carsole.jpg' alt='carsole' />
            
            </div>
        );
    }
}

export default Header;