import React, { Component } from 'react';
import '../Sass/Input.scss'
class SubmitButton extends Component {
    render() {
        return (
            <>
            <button className='Btn' onClick={this.props.onClick} >{this.props.value}</button>
            </>
        );
    }
}

export default SubmitButton;