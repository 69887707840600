import React, { Component } from 'react';
import Input from './Input';
import SideForm from './SideForm.js'
import SubmitButton from './SubmitButton.js'
import '../Sass/Register.scss'
import ForgetPassword from './ForgetPassword';
import RememberMe from './RememberMe';
import CreateAccount from './CreateAccount.js'
import axios from 'axios'
import { Alert, AlertTitle } from '@material-ui/lab';
import { Redirect } from 'react-router';
class AdminLogin extends Component {
    state = {
     Checked : false,
     adminID: '',
     adminRedirect: false
    }
    handleChange = (e) => {
     this.setState({
         [e.target.name]: e.target.value
     })
    }
     handleCheck = () => {
         this.setState({
             Checked: !this.state.Checked
         })
     }
     handleSubmit = () => {
        axios.post(`https://lmsapi.liamcrest.com/api/admin_auth`, this.state)
        .then((res) => {
            if(res.data.status == 'success'){
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('isAdmin', true)
             this.setState({
                 adminID: res.data.payload.user_data.id,
                 adminRedirect: true
             })
            }
            else if(res.data.status == 'error'){
                localStorage.setItem('token', false)
                this.setState({
                    error: true,
                    ErrorData: res.data.message
                })
            }
        })
    }
    render() {
        if(this.state.adminRedirect){
            return <Redirect to={`/Dashboard/dash`}/>
        }
        return (
            <>
            <div className='Register'>
             <div className='part-1'>
             <SideForm des='Liam Crest strives to make the learning engaging, accessible, technologically sound and academically rigorous for learners around the world. As a company, we are founded on four main principle that come together to make us best.' value='Admin Login' radio={false}/>
             </div>   
            <div className='part-2'>
            <h2>Login for training</h2>
            {this.state.error && <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                  {this.state.ErrorData} — <strong>check it out!</strong>
                </Alert>}
            <Input key='1' src='../Form/Asset 5.png' type='text' name='user_name' placeholder='Username' onChange={this.handleChange} required={true}/>
            <Input key='2' src='../Form/Asset 7.png' type='password' name='password' placeholder='Password' onChange={this.handleChange} required={true}/>
            <div className='extra'>
            <RememberMe name='Checked' value={this.state.Checked} onChange={this.handleCheck}/>
            <ForgetPassword/>
            </div>
            <center><SubmitButton value='Login' onClick={this.handleSubmit}/></center>
            <CreateAccount to='/Employee_Register'/>
            </div>
            </div>
            </>
        );
    }
}

export default AdminLogin;