import React, { Component } from 'react'
import '../Sass/FileMaterial.scss'
class Title extends Component {
    render() {
        return (
            <div>
            <div className='title'>
            {this.props.src && <img className='img' alt='title' src={this.props.src}/>}<p className='title_p'>{this.props.title}</p>
            </div>
            <img className='line' alt='line1' src='/File/Asset 2.png'/>
            </div>
        );
    }
}

export default Title;