import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';



const useStyles = makeStyles(() => ({
    card: {
        height: "13rem",
        width: '13rem',
        // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'

    },
    img: {
        height: '4rem',
        width: '4rem',
       
    },
    p: {
        backgroundColor: 'white',
        padding: '1% 5%',
        borderRadius: '5px',
    },
    link: {
        textDecoration: 'none',
        color: 'black'
    }

}))
const AddResourceCard = (props) => {
    const classes = useStyles()
    return (
       <Link to={props.link} className={classes.link}>
       <div className={classes.card} style={{backgroundImage: `url('/AddResource/Asset 5.png')`, backgroundPosition: 'center',
        backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat'}}>
         <img className={classes.img} src={props.src} alt='Image' />
        <p className={classes.p} > {props.value} </p>
        </div>
       </Link> 
    )
}

export default AddResourceCard
