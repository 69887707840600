import React from 'react'
import {
    Route,
  } from "react-router-dom";
import CompanyCourse from './CompanyCourse';
import CompanyDash from './CompanyDash';
import CompanyDashboard from './CompanyDashboard';
import CompanyEmployee from './CompanyEmployee';
import CompanyLinks from './CompanyLinks.js';
import CompanyRegistedCourses from './CompanyRegistedCourses';
const CompanyRoutes = () => {
    return (
        <CompanyDashboard>
             <Route exact path='/Company/:id/dashboard' component={CompanyDash} />
             <Route exact path='/Company/:id/employees' component={CompanyEmployee}/>
             <Route exact path='/Company/:id/courses'  component={CompanyCourse}/>     
             <Route exact path='/Company/:id/registed_courses' component={CompanyRegistedCourses} />
             <Route exact path='/Company/:id/links' component={CompanyLinks} />
            
        </CompanyDashboard>
        
    )
}

export default CompanyRoutes
