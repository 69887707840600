import React, {useState, useEffect} from 'react'
import Info from './Info.js'
import {FaBook, FaTrash} from 'react-icons/fa'
import '../Sass/Dashboard.scss'
import DataTable from './DataTable.js'
import axios from 'axios'
import { useParams } from 'react-router'
import withProtectionCompany from './withProtectionCompany.js'

const CompanyEmployee = () => {
    const [row, setRow] = useState([])
   const [render, setRender] = useState(0)
   const {id} = useParams()
    useEffect(() => {
      axios.post(`https://lmsapi.liamcrest.com/api/company_details`,{company_id: id})
      .then((res) => {
        setRow(res.data.data)
      })
    },[])
    useEffect(() => {
      axios.post(`https://lmsapi.liamcrest.com/api/company_details`,{company_id: id})
      .then((res) => {
        setRow(res.data.data)
      })
    },[render])
    const handleDelete = (id) => {
         axios.post('https://lmsapi.liamcrest.com/api/delete_employee', {id: id})
         .then((res) => {
           if(res.data.status === 'success'){
             setRender(render+1)
           }
         })
    }
    let rows = []
    if(row){
    rows = row.map((row) => {
      return <tr>
      <td>{row.first_name}</td>
      <td>{row.last_name}</td>
      <td>{row.email}</td>
      <td>{row.phone_no}</td>
      <td>{row.feild}</td>
      <td><FaTrash className='icon' color='#03375e' size='1rem' onClick={() => handleDelete(row.id)}/></td>
    </tr>
    })
    }
    
    return (
        <div className='dashboard_individual' style={{backgroundImage: 'url("../Dashboard/Asset 19.png")'}}>
        <Info value='Individual'>
            <FaBook color='#03375e' size='1.5rem'/>
        </Info>
        <DataTable>
          {rows}
        </DataTable>
        </div>
    )
}

export default withProtectionCompany(CompanyEmployee) 
