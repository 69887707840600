import React from 'react'
import { FaClock, FaEdit, FaPencilAlt, FaTrashAlt } from 'react-icons/fa'
import '../Sass/QuizCard.scss'
import EditQuestion from './EditQuestion'
import IconButton from './IconButton'
const InternalQuiz = (props) => {
    return (
        <div className='internal_quiz'>
         <div><IconButton className='btnn' color='#1ebb90' value={props.number} /> <p>{props.question}</p></div>
         <div> <EditQuestion quiz_id={props.quiz_id} id={props.id} /> <FaTrashAlt className='icon' color='#03375e' onClick={props.onClick} /> <IconButton className='btnn' color='#1ebb90' value={props.marks} ><FaPencilAlt  color='#03375e'/></IconButton></div>
        </div>
    )
}

export default InternalQuiz
