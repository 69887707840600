import React, { Component } from 'react';
import '../Sass/SideForm.scss'
import Pillar from './Pillar';
class SideForm extends Component {
    state = {  }
    handleRadio = (e) => {
        console.log(e.target.value)
   this.props.onChangeRadio(e.target.value)
    }
    render() {
        return (
            <div className='SideForm' style={{backgroundImage:'url("../Form/Asset 1.png")', backgroundSize:'cover'}}>
             <a href='/'><img alt='Logo' src='../Form/Asset 2.png' /></a>
             <p className='dis'>{this.props.des}</p>
             <p className='btn'>{this.props.value}</p>
             <div className={this.props.radio ? 'btns' : 'invisible'}>
                 <div className='radio' >
                 <input type='radio' value='Individual' name='type' onChange={this.handleRadio}  defaultChecked/>
                <label>Individual</label>
                </div>
                <div className='radio'>
                 <input type='radio' value='Company' name='type' onChange={this.handleRadio} />
                 <label>Company</label>
                </div>
             </div>
             {/* <div className='Pillars'>
                <Pillar key='1' src='../Pillar 1.png' heading='Resourceful' des='We are a group of problem solvers and critical thinkers commited to following through with action.' />
                <Pillar key='2' src='../Pillar 2.png' heading='Responsive' des='We understand the importance of clear communication and make our response style both timely and well-defined.' />
                <Pillar key='3' src='../Pillar 3.png' heading='Collaborative' des='We know we will arrive at the best product for you through a team approach, and we embrace resources from multiple places.' />
                <Pillar key='4' src='../Pillar 4.png' heading='Professional' des='In all of our dealings, we hold to a high set of ethics and an encompassing protocol.' />
             </div> */}
            </div>
        );
    }
}

export default SideForm;