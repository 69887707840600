import React, { useEffect, useState } from 'react'
import DashboardHeader from './DashboardHeader'
import Title from './Title'
import '../Sass/QuizAttempt.scss'
import QuizNavigator from './QuizNavigator'
import QuizQuestion from './QuizQuestion'
import { Button } from '@material-ui/core'
import { FaAngleLeft, FaAngleRight, FaCheck, FaCheckCircle } from 'react-icons/fa'
import axios from 'axios'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Redirect, useParams } from 'react-router-dom'




const renderTime = (dimension, time) => {
    return (
        <div className="time-wrapper" style={{ textAlign: 'center', fontSize: '1.3rem' }}>
            <div className="time">{time}</div>
            <div>{dimension}</div>
        </div>
    );
};
const QuizAttempt = (props) => {
    const { initialMinute = 0, initialSeconds = 0 } = props;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(0);
    const [state, setState] = useState({ total_questions: '', prevButton: false, nextButton: false })
    const [questions, setQuestions] = useState([])
    const [question, setQuestion] = useState({})
    const [questionNo, setQuestionNo] = useState(1)
    const [redirect, setRedirect] = useState(false)
    const { user_id, quiz_id } = useParams()
    useEffect(() => {
        axios.post(`https://lmsapi.liamcrest.com/api/quiz_questions`, { quiz_id: quiz_id, user_id: user_id })
            .then(res => {
                if (res.data.status === 'success') {
                    let quest = res.data.data.questions.map((ques) => ({ ...ques, user_answer: '' }))

                    setMinutes(res.data.data.quiz_detail.time_allowed - 1)
                    setSeconds(59)
                    setState({ ...res.data.data.quiz_detail, total_questions: res.data.data.total_questions, prevButton: true, nextButton: false })
                    setQuestions(quest)
                    setQuestion(quest[0])
                    setQuestionNo(0)
                } else {
                    setRedirect(true)
                }
            })


    }, [])
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                    handleFinish()
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    }, [minutes, seconds])
    const handleAnswer = (e) => {
        setQuestion({ ...question, user_answer: e.target.value })
    }

    const handleNext = () => {
        const index = questions.findIndex(q => q.id === question.id)

        if (index === -1) {
            setQuestions([...questions, question])
        } else {
            let tempQuestions = questions
            tempQuestions[index] = question
            setQuestions(tempQuestions);
        }
        if (questionNo + 1 === state.total_questions) {
            setState({ ...state, nextButton: true })

        }
        else {
            setQuestion(questions[questionNo + 1])
            setQuestionNo(questionNo + 1)
            setState({ ...state, prevButton: false })
        }

    }

    const handlePrev = () => {
        if (questionNo === 0) {
            setState({ ...state, prevButton: true })

        }
        else {

            setQuestionNo(questionNo - 1)
            setQuestion(questions[questionNo - 1])
            setState({ ...state, nextButton: false })

        }

    }

    const handleFinish = () => {
        const index = questions.findIndex(q => q.id === question.id)

        if (index === -1) {
            setQuestions([...questions, question])
        } else {
            let tempQuestions = questions
            tempQuestions[index] = question
            setQuestions(tempQuestions);
        }
        //  setQuestions([...questions, question])
        axios.post(`https://lmsapi.liamcrest.com/api/quiz_answer`, { user_id: user_id, quiz_id: quiz_id, attempts: state.attempts, answers: questions })
            .then((res) => {
                if (res.data.status === 'success') {
                    setRedirect(true)
                }
            })
    }
    if (redirect) {
        return <Redirect to={`/quiz/${quiz_id}/quiz`} />
    }
    return (
        <>
            <DashboardHeader />
            <div className='quiz_attempt' style={{ backgroundImage: `url('/Course/Asset 63.png')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                <center><Title title={`Quiz : ${state.quiz_activity_name}`} src='/Course/Asset 2.png' /></center>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                    <CountdownCircleTimer
                        isPlaying={true}
                        size={120}
                        strokeWidth={6}
                        colors={[["#EF798A"]]}
                        duration={minutes * 60}
                        initialRemainingTime={minutes * 60}

                    >
                        {({ elapsedTime }) =>
                            renderTime("minutes", minutes)
                        }
                    </CountdownCircleTimer>
                    <CountdownCircleTimer
                        isPlaying={true}
                        size={120}
                        strokeWidth={6}
                        colors={[["#218380"]]}
                        duration={seconds}
                        initialRemainingTime={minutes * 60}
                    >
                        {({ elapsedTime }) =>
                            renderTime("seconds", seconds)
                        }
                    </CountdownCircleTimer>
                </div>
                <div className='quiz_attempt_main'>
                    <div className='quiz_attempt_main_navigator'>
                        <QuizNavigator value={state.total_questions} />
                        <Button
                            style={{
                                backgroundColor: '#ffb210',
                                color: 'black',
                                marginTop: '5%'
                            }}
                            variant="contained"
                            color="secondary"
                            startIcon={<FaCheckCircle color='black' />}
                            onClick={handleFinish}
                        >
                            Finish
                        </Button>
                    </div>
                    <div className='quiz_attempt_main_question'>
                        <QuizQuestion key={questionNo + 1} question_no={questionNo + 1} quiz_total_marks={state.total_marks} question={question.question} value={question.user_answer} onAnswer={handleAnswer} />
                    </div>
                </div>
                <div className='quiz_buttons'>
                    <Button
                        style={{
                            backgroundColor: '#1ebb90',
                            color: 'black',
                            marginTop: '5%'
                        }}
                        variant="contained"
                        color="secondary"
                        startIcon={<FaAngleLeft color='black' />}
                        disabled={state.prevButton}
                        onClick={handlePrev}
                    >
                        Previous
                    </Button>


                    <Button
                        style={{
                            backgroundColor: '#1ebb90',
                            color: 'black',
                            marginTop: '5%'
                        }}
                        variant="contained"
                        color="secondary"
                        endIcon={<FaAngleRight color='black' />}
                        disabled={state.nextButton}
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </>
    )
}

export default QuizAttempt
