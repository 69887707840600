import React, { useEffect, useState } from 'react'

import '../Sass/QuizCard.scss'
import AddQuestion from './AddQuestion'
import InternalQuiz from './InternalQuiz.js'
import { useParams } from 'react-router-dom'
import axios from 'axios'
const AddQuestionCard = (props) => {
    const { id } = useParams()
    const [question, setQuestion] = useState([])
    const [del, setDel] = useState(0)
    const handleDelete = (id) => {
        axios.post('https://lmsapi.liamcrest.com/api/delete_question', { id: id })
        .then((res) => {
            if(res.data.status === 'success'){
                setDel(del + 1)
            }
            // setQuestion(res.data.data)
        })
    }
    useEffect(() => {
        axios.post('https://lmsapi.liamcrest.com/api/questions_list', { quiz_activity_id: id })
            .then((res) => {
                setQuestion(res.data.data)
            })
    },[])
    useEffect(() => {
        axios.post('https://lmsapi.liamcrest.com/api/questions_list', { quiz_activity_id: id })
            .then((res) => {
                setQuestion(res.data.data)
            })
    },[del])
    let questions = []
    if (question) {
        questions = question.map((ques, i) => {
          return  <InternalQuiz key={i} number={i + 1} question={ques.question} quiz_id={id} marks={ques.marks} id={ques.id} onClick={() => handleDelete(ques.id)} />
        })
    }
    return (
        <div className='add_question_card'>
            <div className='add_question_p'><p>  </p> <AddQuestion id={id} /> </div>
            {questions}
        </div>
    )
}

export default AddQuestionCard
