import React from 'react'
import { Link } from 'react-router-dom'
import '../Sass/Dashboard.scss'
const Card = (props) => {
    return (
       <Link to={props.link} style={{textDecoration: "none", color: '#03375e'}}> <div className='card' style={{backgroundImage: `url("${props.src}")`}} >
        <p className='card_name'>{props.name}</p>
        <p className='card_value'>{props.value}</p>
        </div>
        </Link>
    )
}

export default Card
