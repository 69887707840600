import React, { Component } from 'react';
import TopicHeading from './TopicHeading';
class CourseTopic extends Component {
    state = {  }
    render() {
        return (
            <div className='course_topic'>
            <TopicHeading heading = {this.props.heading}/>
            <p className='topic_content'>
            {this.props.content}
            </p>
            </div>
        );
    }
}

export default CourseTopic;