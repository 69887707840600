import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    header: {
        padding : "1% 5%",
        backgroundColor: '#1ebb90',
        border: '3px solid white',
        borderRadius: '20px',
        fontWeight: '800'
    }
}))

const AddResourceHeading = (props) => {
   const classes = useStyles()
    return (
        <p className={classes.header}>
          ADD AN ACTIVITY OR RESOURCE 
        </p>
    )
}

export default AddResourceHeading
