import React, { useState } from 'react'
import '../Sass/ForgetPass.scss'
import Input from './Input'
import { Alert, AlertTitle } from '@material-ui/lab';
import SubmitButton from './SubmitButton'
import axios from 'axios';

const ForgetPass = (props) => {
    const [email, setEmail] = useState('')
    const [state, setState] = useState({
        error: false,
        errorData: '',
        success: false
    })
    const handleChange = (e) => {
        setEmail(e.target.value)
    }
    const handleSumbit = () => {
        axios.post(`https://lmsapi.liamcrest.com/api/forgot_password`, { email: email })
            .then((res) => {
                res.data.status === 'error' ? setState({ ...state, error: true, errorData: res.data.message }) : setState({ ...state, success: true })
            })
    }
    if (state.success) {
        return (
            <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                <strong>Email is sent to you!</strong>
            </Alert>
        )
    }
    return (
        <div className='forget_pass'>
            <img src='/ForgetPass/Asset 2.png' alt='forget' className='forget_image' />
            <p className='forget_heading'>Forget Your Password?</p>
            <p className='forget_small_heading'>Enter email address of your account and we will send you a link to reset your password</p>
            {state.error && <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {state.errorData} — <strong>check it out!</strong>
            </Alert>}
            <Input key='1' src='../Form/Asset 5.png' type='text' name='user_name' placeholder='Email' onChange={handleChange} required={true} />
            <SubmitButton value='Submit' onClick={handleSumbit} />
        </div>
    )
}

export default ForgetPass
