import React from 'react'
import DashboardHeader from './DashboardHeader'
import '../Sass/Quiz.scss'
import withProtection from './withProtection'
import Title from './Title.js'
import AddQuestionCard from './AddQuestionCard'
const AddQuizQuestion = () => {
    return (
        <>
        <DashboardHeader/>
        <div className='add_quiz_question' style={{ backgroundImage: 'url("../Course/Asset 63.png")' }}>
        <Title src='/Quiz/Asset 14.png' title='Adding Questions' />
        <AddQuestionCard/>
        </div>
        </>
    )
}

export default withProtection(AddQuizQuestion)
