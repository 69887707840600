import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Input from './Input.js'
import IconButton from './IconButton.js';
import axios from 'axios';
import { Redirect } from 'react-router';
import withProtection from './withProtection.js';

const useStyles = makeStyles((theme) => ({
    modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid white',
    borderRadius: '20px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 'clamp(20rem, 35rem, 40rem)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'center',
    alignItems:'center'
  },
  btn: {
      
      color: "white"
  }
}));

 function AddTopic(props) {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState({Imgname: 'Topic Image', Img: null})
  const [state, setState] = React.useState({})
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleUpload = async(e) => {
      let file = await convertBase64(e.target.files[0])
    setImage({Imgname: e.target.files[0].name, Img: file})
  }
  const handleChange = (e) => {
   setState({...state, [e.target.name]: e.target.value})
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  const handleSubmit = () => {
   axios.post(`https://lmsapi.liamcrest.com/api/add_topic`, {...state, course_id: props.id, image: image.Img })
   .then(res => {
    if(res.data.status == 'success'){
      setRedirect(true)
    }
   })
  }
if(redirect){
  return <Redirect to={`/Course/${props.id}`} />
}
  return (
    <div className={classes.container}>
      <IconButton value='Add Topic' color='#1ebb90' onClick={handleOpen} >
        +
        </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
              <img alt='logo' src='../Course/Asset 13.png'/>
              <h2>Enter Topic Information</h2>
            <Input key={1} type='text' name='name' placeholder='Topic Name' src='../Course/Asset 15.png' onChange={handleChange}/>
            <Input key={2} type='text' name='description' placeholder='Topic Description' src='../Course/Asset 16.png' onChange={handleChange}/>
            <div>
                    <Input key={4} src='../Form/Company_l.png' type='text'  placeholder={image.Imgname} disabled={true} />
                       <div style={{display: 'none'}}> <Input id='file-input' key={3} src='../Form/Company_l.png' type='file' name='logo' placeholder='Company Logo' onChange={handleUpload} required={true} accept="image/*" /> </div>
                        <label for="file-input" style={{margin: '10% 10%'}}>
                            <img src="../Form/Company_logo.png" />
                        </label>
                    </div>
          <IconButton onClick={handleSubmit} className={classes.btn} value='Add Topic' color='#03375e' />
          </div>
          
        </Fade>
      </Modal>
    </div>
  );
}

export default AddTopic