import React from 'react'

const QuizNavigatorQuestion = (props) => {
    return (
        <div className='quiz_navigator_question' style={{backgroundImage: `url('/QuizAttempt/Asset 76.png')`, backgroundPosition:'center', backgroundSize:'cover', backgroundRepeat: 'no-repeat'}}>
        <p>{props.value}</p>
        </div>
    )
}

export default QuizNavigatorQuestion
