import React from 'react'
import { Link } from 'react-router-dom'

const MenuItem = (props) => {
    return (
        <Link to={props.link} style={{ textDecoration: 'none' }}><div className='menu_item' style={{backgroundColor: props.color, width: props.width, height: props.height,}}>
         {props.children}
         <p style={{ padding: '0% 3%', color: props.textColor, fontSize: props.textFont}}>{props.text}</p>
        </div></Link>
    )
}

export default MenuItem
