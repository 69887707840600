import React from 'react'
import '../Sass/QuizAttempt.scss'


const QuizQuestionInfo = (props) => {
    return (
        <pre className='quiz_question_info'>
        {props.kay} : {props.value}
        </pre>
    )
}

export default QuizQuestionInfo
