import { Paper } from '@material-ui/core'
import React from 'react'
import '../Sass/EmployeeList.scss'
import IconButton from './IconButton'
import withProtectionCompany from './withProtectionCompany'
import { useParams } from 'react-router-dom'
const CompanyLinks = (props) => {
    const handleLoginCopy = () => {
        var text = document.getElementById('login').innerText;
        var elem = document.createElement("textarea");
        document.body.appendChild(elem);
        elem.value = text;
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);
    }
    const handleRegisterCopy = () => {
        var text = document.getElementById('register').innerText;
        var elem = document.createElement("textarea");
        document.body.appendChild(elem);
        elem.value = text;
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);
    }
    const { id } = useParams()
    const link = `https://lms.liamcrest.com/Employee_Register/${id}`
    return (
        <div className='company_links'>
        <Paper className='paper' elevation={4}>
            <div className='paper_div'>
                <p style={{fontWeight: '800'}}>Employee Login Link</p>
                <p id='login'>https://lms.liamcrest.com/Employee_Login</p>
                <button className='btn' onClick={handleLoginCopy}>Copy</button>
            </div>
        </Paper>
        <Paper className='paper' elevation={4}>
            <div className='paper_div'>
                <p style={{fontWeight: '800'}}>Employee Registration Link</p>
                <p id='register'>{link}</p>
                 <button className='btn' onClick={handleRegisterCopy}>Copy</button>
            </div>
        </Paper>
        </div>
    )
}

export default withProtectionCompany(CompanyLinks)
