import React, { Component, Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import AddQuizQuestion from './AddQuizQuestion';
import AdminLogin from './AdminLogin';
import CompanyRoutes from './CompanyRoutes';
import EmployeeCourses from './EmployeeCourses';
import ForgetPass from './ForgetPass';
import Loading from './Loading';
import QuizAttempt from './QuizAttempt';
import ResetPassword from './ResetPassword';
import UserCourse from './UserNewCourse';
import UserRoutes from './UserRoutes';
// import AccessQuiz from './AccessQuiz';
// import AddFile from './AddFile';
// import AddQuiz from './AddQuiz';
// import AddVideo from './AddVideo';
// import CompanyLogin from './CompanyLogin';
// import CompanyRegister from './CompanyRegister';
// import Course from './Course';
// import DashboardRoutes from './DashboardRoutes';
// import EmployeeLogin from './EmployeeLogin';
// import EmployeeRegister from './EmployeeRegister';
// import FileMaterial from './FileMaterial';
// import Login from './Login';
// import Main from './Main';
// import Register from './Register';
// import VideoMaterial from './VideoMaterial';
const AccessQuiz = React.lazy(() => import('./AccessQuiz'))
const AddFile = React.lazy(() => import('./AddFile'))
const AddQuiz = React.lazy(() => import('./AddQuiz'))
const AddVideo = React.lazy(() => import('./AddVideo'))
const CompanyLogin = React.lazy(() => import('./CompanyLogin'))
const CompanyRegister = React.lazy(() => import('./CompanyRegister'))
const Course = React.lazy(() => import('./Course'))
const DashboardRoutes = React.lazy(() => import('./DashboardRoutes'))
const EmployeeLogin = React.lazy(() => import('./EmployeeLogin'))
const EmployeeRegister = React.lazy(() => import('./EmployeeRegister'))
const FileMaterial = React.lazy(() => import('./FileMaterial'))
const Login = React.lazy(() => import('./Login'))
const Main = React.lazy(() => import('./Main'))
const Register = React.lazy(() => import('./Register'))
const VideoMaterial = React.lazy(() => import('./VideoMaterial'))
const Payment = React.lazy(() => import('./Payment.js'))
const EmployeeList = React.lazy(() => import('./EmployeeList.js'))
const AddResource = React.lazy(() => import('./AddResource.js'))
const EditFile = React.lazy(() => import('./EditFile.js'))
const EditURL = React.lazy(() => import('./EditURL.js'))
const EditVideo = React.lazy(() => import('./EditVideo.js'))
const EditQuiz = React.lazy(() => import('./EditQuiz.js'))
const UserNewCourse = React.lazy(() => import('./UserNewCourse.js'))
const AddURL = React.lazy(() => import('./AddURL.js'))
class Routes extends Component {
    state = {}
    render() {
        return (
            <Router>
                <Suspense fallback={<Loading />}>
                    <Route exact path='/' component={Main} />
                    <Route path='/Register' component={Register} />
                    <Route path='/Employee_Register/:company_id' component={EmployeeRegister} />
                    <Route path='/Login' component={Login} />
                    <Route path='/Admin_Login' component={AdminLogin} />
                    <Route path='/Employee_Login' component={EmployeeLogin} />
                    <Route path='/Company_Login' component={CompanyLogin} />
                    <Route path='/Company_Register' component={CompanyRegister} />
                    <Route path='/file/:id/:type' component={FileMaterial} />
                    <Route path='/Course/:id' component={Course} />
                    <Route path='/video/:id/:type' component={VideoMaterial} />
                    <Route path='/quiz/:id/:type' component={AccessQuiz} />
                    <Route path='/Dashboard' component={DashboardRoutes} />
                    <Route path='/Add_video/:id/:topic_id' component={AddVideo} />
                    <Route path='/Add_file/:id/:topic_id' component={AddFile} />
                    <Route path='/Add_quiz/:id/:topic_id' component={AddQuiz} />
                    <Route path='/Add_url/:id/:topic_id' component={AddURL} />
                    <Route path='/Add_question/:id' component={AddQuizQuestion} />
                    <Route path='/Company/:id' component={CompanyRoutes} />
                    <Route path='/:course_id/:id/register_employees' component={EmployeeList} />
                    <Route path='/QuizAttempt/:user_id/:quiz_id' component={QuizAttempt} />
                    <Route path='/Edit_file/:id/:topic_id/:activity_id/:type' component={EditFile} />
                    <Route path='/Edit_url/:id/:topic_id/:activity_id/:type' component={EditURL} />
                    <Route path='/Edit_video/:id/:topic_id/:activity_id/:type' component={EditVideo} />
                    <Route path='/Edit_quiz/:id/:topic_id/:activity_id/:type' component={EditQuiz} />
                    <Route path='/User_dashboard/:id' component={UserRoutes} />
                    <Route path='/employee/:company_id/:id/courses' component={EmployeeCourses} />
                    <Route exact path='/user_course/:id/:course_id' component={UserNewCourse} />
                    <Route exact path='/Payment/:id' component={Payment} />
                    <Route path='/forget_password' component={ForgetPass} />
                    <Route path='/reset_password/:token/:email' component={ResetPassword} />
                </Suspense>

            </Router>
        );
    }
}

export default Routes;