import React from 'react'
import { FaCheckSquare, FaEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import '../Sass/Dashboard.scss'
import IconButton from './IconButton'
import TopicHeading from './TopicHeading'

const CourseCard = (props) => {
    return (
        <Link to={props.id} style={{textDecoration: "none", color: '#03375e', width: '80%'}}>
        <div className='course_card'>
         <img style={{margin: '0% 5%' , height: '10rem', width: '10rem', borderRadius: '50%'}} alt='icon' src={props.src}/>
         <div>
             <TopicHeading heading={props.heading} onClick={props.onClick} />
             <p className='course_card_topic'>{props.detail}</p>
             {props.register && <IconButton className='register_btn' color='#1ebb90' value='Register' onClick={props.register} ><FaCheckSquare color='#03375e'  /></IconButton> }  
         </div>
        </div>
        </Link>
    )
}

export default CourseCard
