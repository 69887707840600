import React, { useState } from 'react'
import '../Sass/ForgetPass.scss'
import Input from './Input'
import { Alert, AlertTitle } from '@material-ui/lab';
import SubmitButton from './SubmitButton'
import axios from 'axios';
import { Redirect, useParams } from 'react-router-dom';
const ResetPassword = (props) => {
    const [password, setPassword] = useState('')
    const [cpassword, setCpassword] = useState('')
    const [state, setState] = useState({
        error: false,
        errorDate: '',
        success: false,
        successData: ''
    })
    const { token, email } = useParams()
    const handleChange = (e) => {
        e.target.name === 'password' ? setPassword(e.target.value) : setCpassword(e.target.value)
    }
    const handleSumbit = () => {
        axios.post(`https://lmsapi.liamcrest.com/api/reset_password`, { password, rpassword: cpassword, token, email })
            .then((res) => {
                res.data.status === 'error' ? setState({ ...state, error: true, errorData: res.data.message }) : setState({ ...state, success: true })
            })
    }
    if (state.success) {
        return (
            <Redirect to='/login' />
        )
    }
    return (
        <div className='forget_pass'>
            <img src='/ForgetPass/Asset 2.png' alt='forget' className='forget_image' />
            <p className='forget_heading'>Reset Your Password?</p>
            <p className='forget_small_heading'>Enter new password for your account</p>
            {state.error && <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {state.errorData} — <strong>check it out!</strong>
            </Alert>}
            <Input key='2' src='/Form/Asset 7.png' type='password' name='password' placeholder='Password' onChange={handleChange} required={true} />
            <Input key='3' src='/Form/Asset 7.png' type='password' name='cpassword' placeholder='Confirm Password' onChange={handleChange} required={true} />
            <SubmitButton value='Reset Password' onClick={handleSumbit} />
        </div>
    )
}

export default ResetPassword
